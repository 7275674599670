import { CopyAllSharp } from '@mui/icons-material';
import { IconButton, InputAdornment, SxProps, TextField, Theme, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { CopyToClipboard as RCTC } from 'react-copy-to-clipboard';

type CopyToClipboardProps = {
  value: string;
  showInput?: boolean;
  buttonText: string;
  iconSize?: 'small' | 'large' | 'medium';
  size?: 'small' | 'medium';
  title?: string;
  disabled?: boolean;
  display?: string;
  sx?: SxProps<Theme>;
};

export function CopyToClipboard({
  value,
  showInput = true,
  buttonText,
  title,
  disabled,
  iconSize = 'medium',
  size,
  display,
  sx,
}: CopyToClipboardProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({
    value: '',
    copied: false,
  });

  useEffect(() => {
    setState({ value, copied: false });
  }, [value]);

  const handleChange = (event) => {
    setState({ value: event.target.value, copied: false });
  };

  const onCopy = () => {
    setState({ ...state, copied: true });
    if (state.value) {
      // TODO: How do we make translations work in shared components?
      enqueueSnackbar('Copied!', { persist: false, variant: 'success', autoHideDuration: 2000 });
    }
  };

  return showInput ? (
    <TextField
      fullWidth
      value={state.value}
      onChange={handleChange}
      label={title}
      size={size}
      disabled={disabled}
      sx={sx}
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position="end">
              <RCTC text={state.value} onCopy={onCopy}>
                <Tooltip title={buttonText}>
                  <IconButton className="copy-to-clipboard-icon-button">
                    <CopyAllSharp fontSize={iconSize} />
                  </IconButton>
                </Tooltip>
              </RCTC>
            </InputAdornment>
          ),
        },

        htmlInput: { 'data-cy': 'copy-clipboard-text-field' },
      }}
    />
  ) : (
    <RCTC text={state.value} onCopy={onCopy} display={display} sx={sx}>
      <Tooltip title={title ?? buttonText}>
        <IconButton className="copy-to-clipboard-icon-button" size={iconSize}>
          <CopyAllSharp fontSize={iconSize} />
        </IconButton>
      </Tooltip>
    </RCTC>
  );
}
