import { OrganizationContext, PRODUCT_PLANS, useLocalStorage } from '@diagrid/cloud-ui-shared';
import { IconButtonAnimate, MenuPopover } from '@diagrid/cloud-ui-shared/components';
import {
  DownloadSharp,
  HelpSharp,
  InsightsSharp,
  MenuBookSharp,
  NotesSharp,
  QuestionAnswerSharp,
  SupportAgentSharp,
  TourSharp,
} from '@mui/icons-material';
import { Link, ListItemIcon, ListItemText, MenuItem, Stack } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { OnboardingModal } from 'src/components/cluster/onboarding-modal/OnboardingModal';
import { DialogDownloadDashboard } from 'src/components/dialog/DialogDownloadDashboard';
import { TOUR_SETTINGS } from 'src/components/tours/constants';
import { useHubspotChat } from 'src/hooks/useHubspot';
import { useLocales } from 'src/hooks/useLocales';
import { useToursState } from 'src/hooks/useToursState';
import { LINKS } from 'src/utils/external-links';

const {
  mcp: { free },
} = PRODUCT_PLANS;

export function HelpPopover() {
  const { translate } = useLocales();
  const { completedUITours, isTourOpen, isLoading } = useToursState();
  const { planName } = useContext(OrganizationContext);
  const [open, setOpen] = useState(null);
  const [showTourModal, setShowTourModal] = useState(false);
  const [closedByUser, setClosedByUser] = useLocalStorage('tourClosedByUser', false);
  const [showDashboardDialog, setShowDashboardDialog] = useState(false);
  const { hasLoaded: liveChatReady, openHandler } = useHubspotChat();

  const isFreePlan = planName === free;

  useEffect(() => {
    // Don't show the modal on cypress for now
    // TODO: remove this when we have a better solution, and testing the modal itself.
    if (window.Cypress || !planName) return;

    if (isTourOpen || isLoading) return;
    const showOnboarding = !completedUITours?.includes(TOUR_SETTINGS.DISMISS);
    const allToursFinished = Object.keys(TOUR_SETTINGS)
      .filter((tour) => TOUR_SETTINGS[tour] !== TOUR_SETTINGS.DISMISS)
      .every((tour) => completedUITours?.includes(TOUR_SETTINGS[tour]));

    if (closedByUser) {
      setShowTourModal(false);
      return;
    }

    if (showOnboarding && !allToursFinished) {
      setShowTourModal(true);
    } else {
      setShowTourModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedUITours, isFreePlan, isLoading, isTourOpen, planName]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleOpenLiveChat = () => {
    if (liveChatReady) {
      openHandler();
      setOpen(null);
    }
  };

  const handleTourOpen = () => {
    setShowTourModal(true);
    setOpen(false);
  };

  const handleDownloadGraphanaDashboard = () => {
    setShowDashboardDialog(true);
    setOpen(false);
  };

  const handleDownloadDashboardClose = () => {
    setShowDashboardDialog(false);
    setOpen(false);
  };

  const onDialogClose = () => {
    setShowTourModal(false);
    setClosedByUser(true);
  };

  return (
    <>
      <IconButtonAnimate
        id="help-button-nav-item"
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && { bgcolor: 'action.selected' }),
        }}
      >
        <HelpSharp />
      </IconButtonAnimate>

      <MenuPopover
        id="help-popover"
        disabledArrow={false}
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 'auto',
          maxWidth: 280,
          minWidth: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          <MenuItem id="help-item-docs" component={Link} href={LINKS.docs} target="_blank" rel="noopener">
            <ListItemIcon>
              <MenuBookSharp />
            </ListItemIcon>
            <ListItemText>{translate('generalLabels.documentation')}</ListItemText>
          </MenuItem>

          <MenuItem id="help-item-tour" onClick={handleTourOpen}>
            <ListItemIcon>
              <TourSharp />
            </ListItemIcon>
            <ListItemText>{translate('generalLabels.productTour')}</ListItemText>
          </MenuItem>

          <MenuItem id="help-item-release-notes" component={Link} href={LINKS.releaseNotes} target="_blank" rel="noopener">
            <ListItemIcon>
              <NotesSharp />
            </ListItemIcon>
            <ListItemText>{translate('generalLabels.releaseNotes')}</ListItemText>
          </MenuItem>

          {!isFreePlan && (
            <MenuItem id="help-item-download-dashboard" onClick={handleDownloadGraphanaDashboard}>
              <ListItemIcon>
                <DownloadSharp />
              </ListItemIcon>
              <ListItemText>{translate('generalLabels.downloadDashboard')}</ListItemText>
            </MenuItem>
          )}
          <MenuItem id="help-item-uptime" component={Link} target="_blank" href={LINKS.uptime}>
            <ListItemIcon>
              <InsightsSharp />
            </ListItemIcon>
            <ListItemText>{translate('generalLabels.uptimeStatus')}</ListItemText>
          </MenuItem>
          {liveChatReady && !isFreePlan && (
            <MenuItem id="help-item-live-chat" onClick={handleOpenLiveChat}>
              <ListItemIcon>
                <SupportAgentSharp />
              </ListItemIcon>
              <ListItemText>{translate('generalLabels.chat')}</ListItemText>
            </MenuItem>
          )}
          <MenuItem id="help-item-community" component={Link} href={LINKS.community} target="_blank" rel="noopener">
            <ListItemIcon>
              <QuestionAnswerSharp />
            </ListItemIcon>
            <ListItemText>{translate('generalLabels.community')}</ListItemText>
          </MenuItem>
        </Stack>
      </MenuPopover>
      <OnboardingModal setDialogClose={onDialogClose} dialogOpen={showTourModal} />
      <DialogDownloadDashboard isOpen={showDashboardDialog} onClose={handleDownloadDashboardClose} />
    </>
  );
}
