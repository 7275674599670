import { CheckBoxOutlineBlankRounded, CheckBoxRounded } from '@mui/icons-material';
import { Box, alpha, useTheme } from '@mui/material';

type SectionButtonProps = {
  onClick: () => void;
  text: string;
  isFinished?: boolean;
};

export const SectionButton = ({ onClick, text, isFinished }: SectionButtonProps) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const colorStyleDarkUnfinished = isDarkMode ? '#ffffff' : theme.palette.primary.dark;
  return (
    <Box
      id={`section-button-${text}`}
      onClick={onClick}
      sx={{
        background: isFinished ? theme.palette.primary.dark : alpha(theme.palette.primary.dark, 0.2),
        color: isFinished ? theme.palette.primary.contrastText : colorStyleDarkUnfinished,
        padding: theme.spacing(1.5, 2),
        borderRadius: '4px',
        marginTop: theme.spacing(2),
        display: 'grid',
        gridTemplateColumns: '1fr 24px',
        gridGap: theme.spacing(2),
        justifyContent: 'space-between',
        fontWeight: 700,
        '&:hover': {
          cursor: 'pointer',
          background: theme.palette.secondary.main,
        },
      }}
    >
      {text}
      {isFinished ? <CheckBoxRounded /> : <CheckBoxOutlineBlankRounded />}
    </Box>
  );
};
