import { OrganizationContext, PRODUCT_PLANS } from '@diagrid/cloud-ui-shared';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useTour } from '@reactour/tour';
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import YouTube, { YouTubeProps } from 'react-youtube';
import { TOUR_SETTINGS } from 'src/components/tours/constants';
import {
  advisoriesSteps,
  applicationsSteps,
  clusterSteps,
  setAdvisoriesStep,
  setApplicationsSteps,
  setClusterStep,
} from 'src/components/tours/scenarios';
import { TourType, ToursState } from 'src/contexts/ToursStateContext';
import { useLocales } from 'src/hooks/useLocales';
import { useToursState } from 'src/hooks/useToursState';
import { SectionButton } from './SectionButton';

type OnboardingModalProps = {
  dialogOpen: boolean;
  setDialogClose: () => void;
};

export function OnboardingModal({ dialogOpen, setDialogClose }: OnboardingModalProps) {
  const navigate = useNavigate();
  const { translate } = useLocales();

  const { planName } = useContext(OrganizationContext);
  const { setIsOpen, setSteps, setCurrentStep } = useTour();
  const { setTourState, completedUITours, submitCompletedUITours, ...tourState } = useToursState();

  const isFreePlan = planName === PRODUCT_PLANS.mcp.free;

  const startTour = (type: TourType) => {
    setDialogClose();

    const newState = {
      isTourOpen: true,
      tourType: type,
    } as ToursState;
    let newSteps = [];

    switch (type) {
      case 'connect-cluster':
        setClusterStep(0, navigate, setCurrentStep, setTourState, submitCompletedUITours);
        newSteps = clusterSteps;
        break;
      case 'explore-advisories':
        newState.isShowDemoCluster = true;
        setAdvisoriesStep(0, navigate, setCurrentStep, setTourState, submitCompletedUITours);
        newSteps = advisoriesSteps;
        break;
      case 'explore-applications':
        newState.isShowDemoCluster = true;
        setApplicationsSteps(0, navigate, setCurrentStep, setTourState, submitCompletedUITours, tourState);
        newSteps = applicationsSteps;
        break;
      default:
        break;
    }

    // to set steps dynamically you don't update the steps array directly, there's a method for that: setSteps
    // You can't managed the steps in the TourProviderWrapper because you're trying to use the setSteps method before the provider is mounted....I think. It def has something to do with how the context is wrapped and initialized.
    setTourState(newState);
    setTimeout(() => {
      document.body.style.overflow = 'hidden';
      setSteps(newSteps);
      setIsOpen(true);
    }, 400);
  };

  const onDismiss = async () => {
    setDialogClose();
    submitCompletedUITours(TOUR_SETTINGS.DISMISS);
  };

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    event.target.pauseVideo();
  };

  const opts: YouTubeProps['opts'] = {
    playerVars: {
      autoplay: 1,
      rel: 0,
    },
  };

  return (
    <Dialog
      id="onboarding-dialog"
      open={dialogOpen}
      sx={{ '& .MuiDialog-paper': { width: '80%' } }}
      onClose={setDialogClose}
      maxWidth={isFreePlan ? 'lg' : 'sm'}
    >
      <DialogTitle
        sx={{
          fontSize: '2rem !important',
          marginBottom: '1rem',
        }}
      >
        {translate('tour.modal.title')}
      </DialogTitle>
      {!isFreePlan && (
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            {translate('tour.modal.description')}
          </Typography>

          <SectionButton
            onClick={() => startTour('connect-cluster')}
            text={translate('tour.modal.steps.clusterScenario')}
            isFinished={completedUITours.includes(TOUR_SETTINGS.CLUSTER_FINISHED)}
          />
          <SectionButton
            onClick={() => startTour('explore-advisories')}
            text={translate('tour.modal.steps.advisoriesScenario')}
            isFinished={completedUITours.includes(TOUR_SETTINGS.ADVISORIES_FINISHED)}
          />
          <SectionButton
            onClick={() => startTour('explore-applications')}
            text={translate('tour.modal.steps.applicationScenario')}
            isFinished={completedUITours.includes(TOUR_SETTINGS.APPLICATIONS_FINISHED)}
          />
        </DialogContent>
      )}
      {isFreePlan && (
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            {translate('tour.modal.freeDescription')}
          </Typography>
          <Box
            sx={{
              mt: 2,
              '& > div': {
                position: 'relative',
                paddingBottom: '56%',
                height: 0,
              },
              '& iframe': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              },
            }}
          >
            <div>
              <YouTube videoId="fg3yuixf4Ss" opts={opts} onReady={onPlayerReady} />
            </div>
          </Box>
        </DialogContent>
      )}
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {!completedUITours.includes(TOUR_SETTINGS.DISMISS) && (
            <Button onClick={onDismiss} data-cy="dismiss-tour" id="onboarding-dialog-dismiss-button">
              {translate('tour.modal.dontShow')}
            </Button>
          )}
        </div>
        <Button variant="contained" onClick={() => setDialogClose()} id="onboarding-dialog-close-button">
          {translate('generalLabels.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
