/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';

declare global {
  interface Window {
    pendo: any;
  }
}

type PendoTrackData = Record<string, unknown>;

type UsePendoInitializer = {
  useFindQuery?: any;
  product?: string;
};

export const usePendo = ({ useFindQuery, product = 'Catalyst' }: UsePendoInitializer) => {
  const pendoTrack = useCallback((name: string, data: PendoTrackData = {}, retries = 0) => {
    if (window?.Cypress) return;
    const debug = localStorage.getItem('debug') === 'true';

    if (window?.pendo?.isReady?.()) {
      if (debug) {
        console.info('Tracking event', name, data);
      }
      return window.pendo.track(name, data);
    }

    if (retries >= 5) {
      if (debug) {
        console.info('Pendo failed to initialize after 5 attempts, event not tracked');
      }
      return;
    }

    // the first 2 retries are silent
    if (retries >= 2) {
      if (debug) {
        console.info('Pendo not initialized, retrying...');
      }
    }

    setTimeout(() => {
      pendoTrack(name, data, retries + 1);
    }, 500);
  }, []);

  return {
    trackEvent: pendoTrack,
  };
};
