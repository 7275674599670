import { useAuth0 } from '@auth0/auth0-react';
import { OrganizationContext, PRODUCT_PLANS, useLocalStorage } from '@diagrid/cloud-ui-shared';
import { CopyToClipboard, IconButtonAnimate, MenuPopover } from '@diagrid/cloud-ui-shared/components';
import { CorporateFareSharp, SearchRounded } from '@mui/icons-material';
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocales } from 'src/hooks/useLocales';

export default function OrganizationPopover(): JSX.Element {
  const { translate } = useLocales();
  const filterOptions = useMemo(
    () => [
      { value: 'all', label: translate('generalLabels.all') },
      { value: 'customer', label: translate('generalLabels.customer') },
      { value: 'selfServe', label: translate('generalLabels.selfServe') },
      { value: 'enterprise', label: translate('generalLabels.enterprise') },
      { value: 'free', label: translate('generalLabels.free') },
    ],
    [translate]
  );

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const {
    organization: currentOrg,
    productOrganizations: userOrgs,
    setDefaultOrg,
    loading: isLoadingOrganization,
  } = useContext(OrganizationContext);
  const { user: auth0User } = useAuth0();
  const [currentOrgIdLocalStorageOrAuth0] = useLocalStorage<string>(`${auth0User?.sub}/currentOrg`);
  const [tokens, _setTokens, removeTokens] = useLocalStorage<string>('tokens');

  const currentOrgSSOEnabled = useMemo(() => currentOrg?.ssoEnabled ?? false, [currentOrg]);

  const [open, setOpen] = useState<HTMLElement | null>(null);
  const [userOrgsFiltered, setUserOrgsFiltered] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState(filterOptions[0].value);

  const isInternalUser = auth0User?.email?.includes('diagrid.io');

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };
  useEffect(() => {
    setUserOrgsFiltered(userOrgs);
  }, [userOrgs]);

  useEffect(() => {
    const { mcp } = PRODUCT_PLANS;

    let filteredOrgs = userOrgs;
    if (searchTerm) {
      filteredOrgs = filteredOrgs.filter((org) => org.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    if (isInternalUser) {
      switch (filter) {
        case 'customer':
          filteredOrgs = filteredOrgs.filter(
            (org) => (org.products.mcp.plan === mcp.business || org.products.mcp.plan === mcp.enterprise) && !org.accessUntil
          );
          break;
        case 'selfServe':
          filteredOrgs = filteredOrgs.filter((org) => org.products.mcp.plan === mcp.trial);
          break;
        case 'enterprise':
          filteredOrgs = filteredOrgs.filter(
            (org) => (org.products.mcp.plan === mcp.business || org.products.mcp.plan === mcp.enterprise) && org.accessUntil
          );
          break;
        case 'free':
          filteredOrgs = filteredOrgs.filter((org) => org.products.mcp.plan === mcp.free);
          break;
        default:
          break;
      }
    }
    setUserOrgsFiltered(filteredOrgs);
  }, [searchTerm, userOrgs, filterOptions, isInternalUser, filter]);

  const handleChangeOrg = (orgId: string) => async () => {
    if (orgId !== currentOrgIdLocalStorageOrAuth0) {
      if (tokens) {
        removeTokens();
      }
      await setDefaultOrg(orgId, '/');
    }
    handleClose();
  };

  return (
    <>
      <IconButtonAnimate
        aria-label="current-organization"
        id="organization-switcher-button"
        size="medium"
        onClick={handleOpen}
        sx={{
          ...(open && { bgcolor: 'secondary' }),
        }}
        children={<CorporateFareSharp />}
      />

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          p: 2,
          width: '17rem',
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h5" gutterBottom>
            {translate('generalLabels.organization')}
          </Typography>
          <CopyToClipboard
            sx={{ mt: '-8px' }}
            value={userOrgs[0]?.id}
            title={translate('generalLabels.copyResource', { resourceType: translate('generalLabels.orgId') })}
            buttonText={translate('generalLabels.copyResource', { resourceType: translate('generalLabels.orgId') })}
            showInput={false}
            iconSize="small"
          />
        </Box>
        {isLoadingOrganization && (
          <Box sx={{ mb: 1 }}>
            <LinearProgress />
          </Box>
        )}
        {userOrgs.length >= 8 && (
          <TextField
            fullWidth
            disabled={isLoadingOrganization}
            size="small"
            placeholder={translate('generalLabels.search')}
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
            sx={{ mb: 1 }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRounded />
                  </InputAdornment>
                ),
                id: 'organization-switcher-search',
              },
            }}
          />
        )}
        {isInternalUser && (
          <FormControl fullWidth sx={{ mb: 2, mt: 1 }}>
            <InputLabel id="show-select-label" size="small">
              {translate('generalLabels.show')}
            </InputLabel>
            <Select
              labelId="show-select-label"
              id="organization-type-select"
              value={filter}
              label={translate('generalLabels.show')}
              size="small"
              onChange={(event) => setFilter(event.target.value)}
              fullWidth
              autoFocus
              disabled={isLoadingOrganization}
            >
              {filterOptions.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {userOrgsFiltered.length > 0 ? (
          <Stack
            spacing={0.75}
            sx={{
              maxHeight: '20rem',
              overflowX: 'hidden',
              overflowY: 'auto',

              '&::-webkit-scrollbar': {
                width: '0.6rem',
              },
              '&::-webkit-scrollbar-track': {
                background: isDarkMode ? theme.palette.grey[800] : theme.palette.grey[200],
                borderRadius: '0.5rem',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: isDarkMode ? theme.palette.grey[600] : theme.palette.grey[600],
                borderRadius: '0.5rem',
              },
            }}
          >
            {userOrgsFiltered.map(({ id, name }) => (
              <MenuItem
                dense
                key={id}
                id={`organization-switcher-item-${id}`}
                selected={id === currentOrgIdLocalStorageOrAuth0}
                disabled={id === currentOrgIdLocalStorageOrAuth0 || isLoadingOrganization}
                onClick={handleChangeOrg(id)}
                sx={
                  id === currentOrg.id && currentOrgSSOEnabled
                    ? {
                        paddingY: '1.8rem',
                      }
                    : {}
                }
              >
                <ListItemText
                  primary={name}
                  secondary={id === currentOrg.id && currentOrgSSOEnabled && 'SSO enabled'}
                  sx={{
                    width: 210,
                    '& .MuiTypography-root': {
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    },
                  }}
                />
              </MenuItem>
            ))}
          </Stack>
        ) : (
          <Typography variant="body2" color="GrayText" gutterBottom>
            {translate('generalLabels.noOrgs')}
          </Typography>
        )}
      </MenuPopover>
    </>
  );
}
