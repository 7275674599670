import { useAuth0 } from '@auth0/auth0-react';
import { IconButtonAnimate } from '@diagrid/cloud-ui-shared/components';
import { useLocalStorage, useResponsive } from '@diagrid/cloud-ui-shared/hooks';
import { cssUtils } from '@diagrid/cloud-ui-shared/utils/theme';
import { authz } from '@diagrid/shared-js';
import { ChevronRight, ChevronRightOutlined, MenuOpenSharp, SearchRounded } from '@mui/icons-material';
import { alpha, AppBar, Box, ClickAwayListener, Link, ListItemButton, Stack, Toolbar, useTheme } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { useEffect, useMemo, useRef, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { Logo } from 'src/components/Logo';
import { withRbac } from 'src/components/rbac/withRbac';
import { NAVBAR } from 'src/config';
import { useKeyboardShortcut } from 'src/hooks/useKeyboardShortcut';
import { useLocales } from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import { useToursState } from 'src/hooks/useToursState';
import { useFindQuery } from 'src/redux/store';
import { LINKS } from 'src/utils/external-links';
import { AccountPopover } from './AccountPopover';
import { HelpPopover } from './HelpPopover';
import { NotificationsPopover } from './NotificationsPopover';
import OrganizationPopover from './OrganizationPopover';
import { QuickFindDrawer } from './QuickFindDrawer';

const RestrictedNotificationsPopover = withRbac(NotificationsPopover);

const notificationsOperation = {
  resource: authz.model.resources.clusterDiagridnotifications,
  verb: [authz.model.verbs.list],
};

type DashboardHeaderProps = {
  onOpenSidebar: () => void;
  isCollapse?: boolean;
  verticalLayout?: boolean;
  trialDate?: string;
};

export function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false, trialDate }: DashboardHeaderProps) {
  const { translate } = useLocales();
  const theme = useTheme();
  const { isTourOpen } = useToursState();
  const settings = useSettings();
  const { data: org } = useFindQuery({ type: 'userOrganizations', normalization: { raw: true } });
  const { user } = useAuth0();
  const [currentOrgId] = useLocalStorage(`${user?.sub}/currentOrg`, user?.defaultOrganization);

  const [showQuickFind, setShowQuickFind] = useState(false);

  const handleQuickFind = () => {
    setShowQuickFind((prev) => !prev);
  };

  const handleEscape = () => {
    setShowQuickFind(false);
  };
  useKeyboardShortcut('k', () => handleQuickFind(), true);
  useKeyboardShortcut('Escape', handleEscape, false);

  const organization = useMemo(() => org?.data?.find((o) => o.id === currentOrgId) ?? {}, [currentOrgId, org]);
  const ref = useRef(null);

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (ref?.current?.clientHeight) {
      settings.onChangeHeaderHeight(ref?.current?.clientHeight);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.current?.clientHeight]);

  const timeLeft = useMemo(() => {
    function getTrialTime(trialDate) {
      if (!trialDate) return {};
      const date = new Date(trialDate);
      const now = new Date();
      const diff = date.getTime() - now.getTime();
      const floatingDays = diff / (1000 * 60 * 60 * 24);
      const days = floatingDays > 1 ? Math.ceil(floatingDays) : Math.floor(floatingDays);
      const hours = Math.ceil(diff / (1000 * 60 * 60));

      let string = '';
      if (days < 1) {
        string = hours > 1 ? `${hours} ${translate('time.hours')}` : `${translate('time.less')} ${hours} ${translate('time.hour')}`;
      } else {
        string = days > 1 ? `${days} ${translate('time.days')}` : `${days} ${translate('time.day')}`;
      }
      return {
        hoursLeft: hours,
        string,
      };
    }

    return getTrialTime(trialDate);
  }, [translate, trialDate]);

  const hasExpired = timeLeft.hoursLeft < 1;
  const markTrialExpireApproaching = timeLeft.hoursLeft <= 168 && timeLeft.hoursLeft > 0;

  const isOrgBlocked = organization?.blocked || false;
  const orgAccessUntil = organization?.blockedTimestamp;
  let orgAccessUntilDate;
  let orgAccessUntilString;

  if (orgAccessUntil) {
    orgAccessUntilDate = new Date(orgAccessUntil);
    orgAccessUntilDate.setDate(orgAccessUntilDate.getDate() + 7);
    orgAccessUntilString = formatDistanceToNow(orgAccessUntilDate);
  }

  return (
    <AppBar
      sx={{
        ...cssUtils(theme).bgBlur(),
        boxShadow: 'none',
        zIndex: theme.zIndex.appBar + 1,
        position: isTourOpen ? 'absolute' : 'fixed',
        transition: theme.transitions.create(['width', 'height'], {
          duration: theme.transitions.duration.shorter,
        }),

        pl: 2,
        [theme.breakpoints.up('lg')]: {
          width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
          ...(isCollapse && {
            width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
          }),
          ...(verticalLayout && {
            width: '100%',
            backgroundColor: theme.palette.background.default,
          }),
        },
      }}
      ref={ref}
    >
      <Box
        sx={{
          width: '100%',
          background: theme.palette.warning.main,
          color: theme.palette.warning.contrastText,
          fontWeight: 700,
          textAlign: 'center',
          padding: theme.spacing(1, 2),
          fontSize: '0.75rem',
          [theme.breakpoints.up(768)]: {
            display: 'none',
          },
        }}
      >
        <BrowserView>{translate('notifications.warnings.smallDesktopNotOptimized')}</BrowserView>
        <MobileView>{translate('notifications.warnings.mobileNotOptimized')}</MobileView>
      </Box>

      {trialDate && !isOrgBlocked && (
        <TrialBanner
          hasExpired={hasExpired}
          expiryApproaching={markTrialExpireApproaching}
          text={hasExpired ? translate('trialBanner.expired') : translate('trialBanner.default', { time: timeLeft.string })}
        />
      )}

      {trialDate && isOrgBlocked && (
        <TrialBanner hasExpired={hasExpired} text={translate('trialBanner.trialExpiredAndBlocked', { date: orgAccessUntilString })} />
      )}

      {!trialDate && isOrgBlocked && <TrialBanner hasExpired={hasExpired} text={translate('trialBanner.blocked')} />}
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <ListItemButton
            onClick={() => handleQuickFind()}
            sx={{
              ...theme.typography.body2,
              position: 'relative',
              height: '2.8rem',
              width: '20rem',
              textTransform: 'capitalize',
              mt: '0.3rem',
              py: 2,
              fontWeight: 700,
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(1.5),
              color: showQuickFind ? theme.palette.primary.main : theme.palette.text.secondary,
              borderRadius: theme.shape.borderRadius,
              marginLeft: theme.spacing(2),
              marginRight: theme.spacing(3),
              backgroundColor: showQuickFind ? alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity) : 'none',
            }}
            id="quick-find-button"
          >
            <SearchRounded sx={{ mr: 1.25 }} /> {translate('components.quickFind.title')}{' '}
            <Box
              component="span"
              sx={{
                ml: 1,
              }}
            >
              ({translate('components.quickFind.command')})
            </Box>
            {showQuickFind && (
              <ChevronRightOutlined sx={{ position: 'absolute', right: theme.spacing(1), top: '50%', transform: 'translateY(-50%)' }} />
            )}
          </ListItemButton>
        </div>
        <Toolbar
          sx={{
            px: { lg: 5 },
            py: 2,
          }}
        >
          {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

          {!isDesktop && (
            <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
              <MenuOpenSharp />
            </IconButtonAnimate>
          )}

          <Box sx={{ flexGrow: 1 }} />

          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            <OrganizationPopover />
            {/* <LanguagePopover currentLang={currentLang} allLangs={allLangs} onChangeLang={onChangeLang} /> */}
            <RestrictedNotificationsPopover operation={notificationsOperation} />
            <HelpPopover />
            <AccountPopover />
          </Stack>
        </Toolbar>
      </Box>
      {showQuickFind && (
        <ClickAwayListener onClickAway={() => setShowQuickFind(false)}>
          <Box>
            <QuickFindDrawer onClose={() => setShowQuickFind(false)} />
          </Box>
        </ClickAwayListener>
      )}
    </AppBar>
  );
}

type TrialBannerProps = {
  hasExpired: boolean;
  text: string;
  expiryApproaching?: boolean;
};

const TrialBanner = ({ hasExpired, text, expiryApproaching }: TrialBannerProps) => {
  const theme = useTheme();
  const { translate } = useLocales();

  const backgroundColor = useMemo(() => {
    if (hasExpired) return theme.palette.error.main;
    if (expiryApproaching) return theme.palette.warning.main;
    return theme.palette.secondary.main;
  }, [expiryApproaching, hasExpired, theme.palette.error.main, theme.palette.secondary.main, theme.palette.warning.main]);

  return (
    <Box
      sx={{
        background: backgroundColor,
        width: '100%',
        color: hasExpired ? 'white' : theme.palette.grey[900],
        padding: theme.spacing(1, 4),
        fontSize: '0.875rem',
        fontWeight: '600',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gridGap: '2rem',
        [theme.breakpoints.down('sm')]: {
          display: 'block',
          padding: theme.spacing(1, 2),
        },
      }}
    >
      <Box>{text}</Box>
      <Link
        id="trial-banner-cta-button"
        sx={{
          color: hasExpired ? 'white' : theme.palette.grey[900],
          display: 'flex',
          alignItems: 'center',
          fontWeight: '600',
          textDecorationColor: hasExpired ? 'white' : theme.palette.grey[900],
          '&:hover': {
            textDecoration: 'none',
          },
        }}
        href={LINKS.mailToSales}
      >
        {translate('trialBanner.callToAction')} <ChevronRight sx={{ fontSize: '1.1rem', mb: 0.1 }} />
      </Link>
    </Box>
  );
};
