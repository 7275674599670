import { useAuth0 } from '@auth0/auth0-react';
import { CUSTOM_CLAIMS } from '@diagrid/cloud-ui-shared';
import { ProgressBarStyle } from '@diagrid/cloud-ui-shared/components';
import { useUnleashClient } from '@unleash/proxy-client-react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NotistackProvider } from './components/NotistackProvider';
import { ScrollToTop } from './components/ScrollToTop';
import { ChartStyle } from './components/chart';
import Router from './routes';
import { security } from './utils';

export default function App() {
  const { getAccessTokenSilently, user, loginWithRedirect, logout, isLoading: isLoadingAuth } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const client = useUnleashClient();

  security.setAccessTokenSilently(getAccessTokenSilently);
  security.setAuth0User(user);
  security.loginWithRedirect = loginWithRedirect;
  security.logout = logout;

  useEffect(() => {
    const clientContext = client.getContext();

    const init = async () => {
      if (user?.email && user?.[CUSTOM_CLAIMS.defaultOrganization]) {
        await client.setContextField('userId', user.email);
        await client.setContextField('userOrgId', user?.[CUSTOM_CLAIMS.defaultOrganization]);
        await client.start();
      }
    };

    if (!isLoadingAuth && user?.email && user?.[CUSTOM_CLAIMS.defaultOrganization] && !clientContext?.userId) {
      init();
    }
  }, [client, isLoadingAuth, user]);

  useEffect(() => {
    if (
      user &&
      !user?.email_verified &&
      location.pathname !== '/verify-email' &&
      location.pathname !== '/sign-up-success' &&
      location.pathname !== '/error'
    ) {
      localStorage.clear();
      logout({
        logoutParams: {
          returnTo: `${window.location.origin}/verify-email`,
          federated: true,
        },
      });
    }
  }, [location.pathname, loginWithRedirect, logout, navigate, user]);

  useEffect(() => {
    const initialize = async () => {
      // whenever there is an special error with the auth0 login we get redirected to something like this
      // http://localhost:3000/?error=access_denied&error_description=not-allowed&state=UjZVQk4xcWN1LUJ%2BNDNERFl2ZGh3VW1ObnV%2BMEZXSlZRQ0FrRmp4d2lobw%3D%3D

      const query = window.location.search;
      if (query.includes('error=access_denied')) {
        let reason = 'Access denied';
        if (query.includes('error_description=')) {
          const searchParams = new URLSearchParams(query);
          reason = searchParams.get('error_description') ?? reason;
        }

        if (reason === 'requires-login-again') {
          loginWithRedirect();
          return;
        }

        navigate(`/error?error_description=${encodeURIComponent(reason)}`, { replace: true });
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NotistackProvider>
      <ProgressBarStyle />
      <ChartStyle />
      <ScrollToTop />
      <Router />
    </NotistackProvider>
  );
}
