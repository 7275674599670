import { AddSharp, DeleteSharp } from '@mui/icons-material';
import { Box, Button, FormControl, FormLabel, Grid2 as Grid, IconButton, MenuItem } from '@mui/material';
import { filter, map, values } from 'lodash';
import { useMemo } from 'react';
import { FieldValues, UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form';

import { RHFAutoCompleteSelect, RHFSelect, ResourceRecord } from 'src/components/hook-form';
import { useLocales } from 'src/hooks/useLocales';
import { DIAGRID_ROLES, DIAGRID_ROLE_DEFINITIONS } from 'src/utils/constants';

type IProps = {
  resourceNames: Array<ResourceRecord>;
  fields: Array<FieldValues>;
  append: UseFieldArrayAppend<FieldValues>;
  remove: UseFieldArrayRemove;
  disabled?: boolean;
  viewerSelected?: boolean;
};

type Props = IProps;

const defaultRoles = map(values(DIAGRID_ROLES), (role) => ({ ...role, value: role.id }));
const nonAdminRoles = filter(defaultRoles, (role) => role.id !== DIAGRID_ROLE_DEFINITIONS.admin);

export function RHFCustomRoles({ resourceNames, fields, append, remove, disabled, viewerSelected }: Props) {
  const { translate } = useLocales();

  // Ideally I would not pass in fields, append, and remove, but instead use useFieldArray
  // but for some reason TS is complaingin about the types of fields, append, and remove not being present on the return value of useFieldArray
  // which is a total lie.  I have no idea why it's doing this or how to override the error.
  // const { fields, append, remove } = useFieldArray({
  //   name: 'customRoles',
  //   control,
  // });

  const handleAddCustomRole = () => {
    append({ level: '', resource: '' });
  };

  const roles = useMemo(() => {
    if (viewerSelected) {
      return nonAdminRoles.filter((role) => role.id !== DIAGRID_ROLE_DEFINITIONS.viewer);
    }
    return nonAdminRoles;
  }, [viewerSelected]);

  return (
    <Box data-cy="rhf-custom-roles-container">
      <FormLabel sx={{ mb: 2, display: 'inline-block' }}>{translate('generalLabels.scopedRoles')}</FormLabel>
      <Grid container spacing={2}>
        {fields.map((_, index) => (
          <Grid container key={`custom-role-new-container-${index}`} size={12} spacing={2}>
            <Grid size={5}>
              <FormControl fullWidth>
                <RHFSelect
                  name={`customRoles[${index}].level`}
                  label={translate('generalLabels.role')}
                  size="small"
                  fullWidth
                  required
                  slotProps={{
                    inputLabel: { shrink: true },
                    select: { native: false, sx: { textTransform: 'capitalize' } },
                  }}
                >
                  <MenuItem disabled key="custom-roles-level-no-choice" value="">
                    {translate('generalLabels.none')}
                  </MenuItem>
                  {/* admin roles do not apply to cluster contexts in this case */}
                  {roles.map((role) => (
                    <MenuItem key={role.label} value={role.value}>
                      {role.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </FormControl>
            </Grid>
            <Grid size={5}>
              <RHFAutoCompleteSelect
                name={`customRoles[${index}].resource`}
                label={translate('generalLabels.cluster')}
                size="small"
                options={resourceNames}
                fullWidth
                required
                valueIsLabel={false}
              />
            </Grid>
            <Grid size={2}>
              <IconButton aria-label="delete" onClick={() => remove(index)}>
                <DeleteSharp />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Box component="div" sx={{ mt: 2 }}>
        <Button disabled={disabled} startIcon={<AddSharp />} onClick={handleAddCustomRole}>
          {translate('generalLabels.scopedRole')}
        </Button>
      </Box>
    </Box>
  );
}
