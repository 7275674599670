import { TOptions } from 'i18next';
import { useTranslation } from 'react-i18next';

export function useLocales() {
  const { t: translate } = useTranslation();

  return {
    // We need to use ANY in here cause the i18next types do not support the use of variables in the translation for interpolation
    // We technically need to use context prop to pass interpolation vars, but we need to change all translations to use context.interpolatedValue
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    translate: (text: string, options?: TOptions & Record<string, any>) => translate(text, options || {}),
  };
}
