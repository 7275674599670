import { CheckSharp } from '@mui/icons-material';
import { Box, Button, FormLabel, SxProps, Theme, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Prism } from 'react-syntax-highlighter';
import { dracula, oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useLocales } from '../hooks/useLocales';
import { LanguageSyntax } from '../types';

type CodeBlockProps = {
  value: string;
  label?: string;
  showCopyButton?: boolean;
  language?: LanguageSyntax;
  displayValue?: string;
  // pass in a string of arrays and it will replace it with ********* only for display
  secrets?: string[];
  sx?: SxProps<Theme>;
  prysmCustomStyles?: {
    [key: string]: unknown;
  };
  [key: string]: unknown;
};

export function CodeBlock({
  value,
  displayValue,
  showCopyButton = true,
  language,
  label = '',
  secrets,
  sx,
  prysmCustomStyles = {},
  ...props
}: CodeBlockProps) {
  const theme = useTheme();
  const [state, setState] = useState({
    value: '',
    copied: false,
  });
  const { translate } = useLocales();
  useEffect(() => {
    setState({ value, copied: false });
  }, [value]);

  const onCopy = () => {
    setState({ ...state, copied: true });
    setTimeout(() => {
      setState({ ...state, copied: false });
    }, 2000);
  };
  const isDarkMode = theme.palette.mode === 'dark';
  const background = isDarkMode ? theme.palette.grey[900] : theme.palette.grey[0];

  const hideSecrets = (text: string, secrets: string[]): string => {
    // Create a regular expression pattern to match all secrets
    const secretsPattern = secrets
      .map((secret) => {
        if (!secret) return null;
        return `(${secret})`;
      })
      .filter(Boolean)
      .join('|');
    if (!secretsPattern) return text;
    const pattern = new RegExp(secretsPattern, 'gi');

    return text.replace(pattern, '●●●●●●●●●●●●●●●●');
  };

  const getDisplayValue = useMemo(() => {
    const fallback = value ? value : ` `;
    const displayFallback = displayValue ? displayValue : fallback;

    if (!secrets || secrets.length < 0) return displayFallback;

    if (secrets && secrets.length > 0) {
      const withSecrets = hideSecrets(value, secrets);
      return withSecrets ? withSecrets : fallback;
    }

    return displayFallback;
  }, [secrets, displayValue, value]);

  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <Box
        sx={{
          background: isDarkMode ? theme.palette.grey[800] : theme.palette.grey[100],
          border: `1px solid ${isDarkMode ? theme.palette.grey[700] : theme.palette.grey[300]}`,
          mb: '-0.5rem',
          padding: '0 0 0.5rem 1rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '0.5rem',
          borderTopLeftRadius: '0.5rem',
          borderTopRightRadius: '0.5rem',
        }}
      >
        <FormLabel sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>{label}</FormLabel>

        <CopyToClipboard text={state.value} onCopy={onCopy}>
          <Button variant="text" size="small">
            {state.copied ? (
              <>
                <CheckSharp fontSize="small" />
                {translate('glossary.copied', { ns: 'shared' })}
              </>
            ) : (
              translate('glossary.copy', { ns: 'shared' })
            )}
          </Button>
        </CopyToClipboard>
      </Box>
      <Box
        sx={{
          background,
          border: `1px solid ${isDarkMode ? theme.palette.grey[700] : theme.palette.grey[300]}`,
          borderRadius: '0.6rem',
          padding: '0 1rem',
          '& code': {
            backgroundColor: 'transparent !important',
            fontSize: isDarkMode ? '1rem' : '0.9rem',
          },
          '& pre': {
            padding: `0 ${showCopyButton ? 2.5 : 0}rem 0.5em 0 !important`,
            '&::-webkit-scrollbar': {
              height: '0.6rem',
              width: '0.6rem',
            },
            '&::-webkit-scrollbar-thumb': {
              background: theme.palette.primary.main,
              borderRadius: '0.5rem',

              '&:hover': {
                background: theme.palette.primary.main,
              },
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent !important',
            },
            '&::-webkit-scrollbar-corner': {
              background: 'transparent !important',
            },
          },
        }}
      >
        <Prism
          data-cy="code-block"
          language={language}
          style={isDarkMode ? dracula : oneLight}
          customStyle={{
            paddingRight: '2.5rem',
            background,
            borderRadius: '0',
            marginTop: '1em',
            zIndex: 1,
            ...{ ...prysmCustomStyles },
          }}
          wrapLines
          {...props}
        >
          {getDisplayValue}
        </Prism>
      </Box>
    </Box>
  );
}
