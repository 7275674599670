import { useAuth0 } from '@auth0/auth0-react';
import { Scrollbar } from '@diagrid/cloud-ui-shared';
import { useLocalStorage } from '@diagrid/cloud-ui-shared/hooks';
import { Box, styled, TextField, Typography, useTheme } from '@mui/material';
import Fuse from 'fuse.js';
import { forEach } from 'lodash';
import { useMemo, useState } from 'react';
import { useLocales } from 'src/hooks/useLocales';
import { useFindQuery } from 'src/redux/store';
import { QuickFindItem, QuickFindItemType } from './QuickFindItem';

const MAX_RESULTS_CLUSTERS = 100;
const MAX_RESULTS_APPS = 100;
const MAX_RECENT_ITEMS = 5;

export function QuickFindDrawer({ onClose }: { onClose: () => void }) {
  const { translate } = useLocales();
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  const [recentItems, setRecentItems] = useLocalStorage<{ [key: string]: QuickFindItemType[] }>('quickFindRecentResources', {});
  const { user } = useAuth0();
  const [currentOrgId] = useLocalStorage(`${user?.sub}/currentOrg`, user?.defaultOrganization);

  const { data: filtersData } = useFindQuery(
    {
      type: 'diagridNotificationsFilters',
      data: {},
      normalization: { skip: true },
    },
    { refetchOnMountOrArgChange: true }
  );

  const data = useMemo(() => {
    const clusterItems = [];
    const appItems = [];
    if (filtersData?.data?.clusters) {
      const { clusters } = filtersData.data;
      forEach(clusters, (cluster) => {
        clusterItems.push({
          id: cluster.id,
          name: cluster.name,
        });
        forEach(cluster.workloads, (workload) => {
          appItems.push({
            ...workload,
            name: workload.id.split(':')[1],
            clusterName: cluster.name,
          });
        });
      });
    }

    return {
      clusterItems,
      appItems,
    };
  }, [filtersData]);

  const { filteredClusters, filteredApps, filteredClustersCount, filteredAppsCount } = useMemo(() => {
    const SCORE_THRESHOLD = 0.05;
    if (searchTerm) {
      const { clusterItems, appItems } = data;
      const fuseClusters = new Fuse(clusterItems, {
        keys: ['id', 'name'],
        includeScore: true,
      });
      const fuseApps = new Fuse(appItems, {
        keys: ['clusterId', 'id', 'name', 'namespace'],
        includeScore: true,
      });
      const filteredClusters = fuseClusters.search(searchTerm).filter((s) => s.score <= SCORE_THRESHOLD);
      const filteredApps = fuseApps.search(searchTerm).filter((s) => s.score <= SCORE_THRESHOLD);
      return {
        filteredClusters: filteredClusters.slice(0, MAX_RESULTS_CLUSTERS),
        filteredClustersCount: filteredClusters.length - MAX_RESULTS_CLUSTERS,
        filteredApps: filteredApps.slice(0, MAX_RESULTS_APPS),
        filteredAppsCount: filteredApps.length - MAX_RESULTS_APPS,
      };
    }
    return {
      filteredClusters: [],
      filteredClustersCount: 0,
      filteredApps: [],
      filteredAppsCount: 0,
    };
  }, [searchTerm, data]);

  const saveToLocalStorage = (item) => {
    const currentRecentItems = recentItems[currentOrgId] ?? [];
    const hasSome = currentRecentItems?.some(({ id }) => id === item.id);

    if (hasSome) {
      setRecentItems({ ...recentItems, [currentOrgId]: [item, ...currentRecentItems.filter(({ id }) => id !== item.id)] });
    } else {
      setRecentItems({ ...recentItems, [currentOrgId]: [item, ...currentRecentItems.slice(0, MAX_RECENT_ITEMS - 1)] });
    }

    onClose();
  };

  const recentItemsList = useMemo(() => {
    // Filter out recent items that no longer exist in data
    const { clusterItems, appItems } = data;
    const validRecentItems = (recentItems[currentOrgId] ?? []).filter((recentItem) => {
      if (recentItem.clusterId) {
        // For apps, check if both cluster and app still exist
        return appItems.some((app) => app.id === recentItem.id && app.clusterId === recentItem.clusterId);
      }
      // For clusters, just check if cluster exists
      return clusterItems.some((cluster) => cluster.id === recentItem.id);
    });

    return validRecentItems ?? [];
  }, [recentItems, currentOrgId, data]);
  const isDarkMode = theme.palette.mode === 'dark';
  return (
    <Box
      sx={{
        position: 'fixed',
        top: '1rem',
        left: '1rem',
        width: '28rem',
        maxHeight: '94vh',
        zIndex: 1000,
        background: isDarkMode ? theme.palette.grey[900] : theme.palette.grey[100],
        boxShadow: isDarkMode ? theme.shadows[10] : theme.shadows[20],
        p: 2,
        pr: 1,
        border: `1px solid ${isDarkMode ? theme.palette.grey[700] : theme.palette.grey[400]}`,
        borderRadius: 1,
      }}
    >
      <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
        {translate('components.quickFind.title')}
      </Typography>
      <TextField
        size="small"
        fullWidth
        sx={{ my: 2, pr: 2 }}
        label={translate('generalLabels.search')}
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        autoFocus
      />
      <Scrollbar sx={{ maxHeight: 'calc(94vh - 9rem)' }} tabIndex={-1}>
        {filteredClusters.length === 0 && filteredApps.length === 0 && searchTerm && (
          <Typography sx={{ fontStyle: 'italic', opacity: 0.5, my: 2, color: theme.palette.text.primary }}>
            {translate('components.quickFind.noResults')}
          </Typography>
        )}
        {filteredClusters.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <StyledQuickFindTittle variant="body2">{translate('generalLabels.clusters')}</StyledQuickFindTittle>

            {filteredClusters.map(({ item }) => (
              <QuickFindItem item={item} key={item.id} onClick={() => saveToLocalStorage(item)} />
            ))}
            {filteredClusters.length === MAX_RESULTS_CLUSTERS && (
              <Typography sx={{ fontStyle: 'italic', opacity: 0.5, my: 2, color: theme.palette.text.primary }}>
                {translate('components.quickFind.moreResults', { count: filteredClustersCount })}
              </Typography>
            )}
          </Box>
        )}
        {filteredApps.length > 0 && (
          <>
            <StyledQuickFindTittle variant="body2">{translate('generalLabels.apps')}</StyledQuickFindTittle>
            {filteredApps.map(({ item }, index) => (
              <QuickFindItem item={item} key={`${item.id}-${item?.clusterId ?? index}`} onClick={() => saveToLocalStorage(item)} />
            ))}
            {filteredApps.length === MAX_RESULTS_APPS && (
              <Typography sx={{ fontStyle: 'italic', opacity: 0.5, my: 2 }}>
                {translate('components.quickFind.moreResults', { count: filteredAppsCount })}
              </Typography>
            )}
          </>
        )}
        {recentItemsList.length > 0 && !searchTerm && (
          <>
            <StyledQuickFindTittle variant="body2">{translate('generalLabels.recent')}</StyledQuickFindTittle>
            {recentItemsList.map((item, index) => (
              <QuickFindItem item={item} key={`${item.id}-${item?.clusterId ?? index}`} onClick={onClose} />
            ))}
          </>
        )}
      </Scrollbar>
    </Box>
  );
}

const StyledQuickFindTittle = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 'bold',
  mt: 3,
  fontSize: '0.875rem',
  opacity: 0.5,
  color: theme.palette.text.primary,
}));
