import { useEffect } from 'react';

export const useKeyboardShortcut = (targetKey, callback, metaKey = true) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      /* Common event.key values:
       * - Letters: 'a' through 'z'
       * - Numbers: '0' through '9'
       * - Special keys: 'Enter', 'Tab', 'Backspace', 'Delete', 'Escape', 'Space'
       * - Arrow keys: 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'
       * - Modifier keys: 'Shift', 'Control', 'Alt', 'Meta'
       * - Function keys: 'F1' through 'F12'
       * - Symbols: '+', '-', '.', '/', ';', '=', ',', '[', ']', '\', etc.
       */

      // Return early if meta key (command/ctrl) is required but not pressed
      if (metaKey && !event.metaKey) return;

      // Return early if the pressed key doesn't match the target key
      if (event.key !== targetKey) return;

      event.preventDefault();
      callback();
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [targetKey, metaKey, callback]);
};
