import { OpenInNewSharp } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@mui/material';
import { HOST_API } from 'src/config';
import { useLocales } from 'src/hooks/useLocales';
import { LINKS } from 'src/utils/external-links';
import { DownloadGenericFile } from '../DownloadGenericFile';

type DialogDownloadDashboardProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function DialogDownloadDashboard({ isOpen, onClose }: DialogDownloadDashboardProps) {
  const { translate } = useLocales();
  const DashBoardUrl = `${HOST_API}/apis/diagrid.io/v1beta1/prometheus/api/v1/dashboard`;

  const onConfirm = () => {
    onClose();
  };

  return (
    <Dialog id="download-dashboard-dialog" open={isOpen} onClose={onClose}>
      <DialogTitle>{translate('components.dialogDownloadDashboard.title')}</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>{translate('components.dialogDownloadDashboard.content')}</Typography>
        <Typography gutterBottom>
          <Link
            target="_blank"
            href={`${LINKS.docs}/how-to-guides/use-grafana-dashboard`}
            sx={{ display: 'inline-flex', alignContent: 'center' }}
          >
            {translate('generalLabels.documentation')}
            <OpenInNewSharp fontSize="inherit" sx={{ ml: 0.5 }} />
          </Link>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} id="download-dashboard-dialog-close-button">
          {translate('generalLabels.cancel')}
        </Button>
        <DownloadGenericFile fileName="grafana-dashboard.json" fileUrl={DashBoardUrl} prependOrgIdToFileName isButton onFinish={onConfirm}>
          {translate('generalLabels.download')}
        </DownloadGenericFile>
      </DialogActions>
    </Dialog>
  );
}
