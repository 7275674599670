import { Radio, FormControlLabel } from '@mui/material';

type BoxMaskProps = {
  value: string;
};

export function BoxMask({ value }: BoxMaskProps) {
  return (
    <FormControlLabel
      label=""
      value={value}
      id={`${value}-mask`}
      control={<Radio sx={{ display: 'none' }} />}
      sx={{
        m: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        position: 'absolute',
      }}
    />
  );
}
