import { Circle } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { memo, useCallback } from 'react';
import { Scrollbar } from '../Scrollbar';

type LegendItem = {
  name: string;
  id: string;
  color: string;
};

type ChartLegendProps = {
  chartId: string;
  legendItems: LegendItem[];
  toggledSeries: string[];
  chartHeight: number;
  onLegendClick: (event: React.MouseEvent, seriesId: string) => void;
  onMouseOver: (event: React.MouseEvent, seriesId: string) => void;
  onMouseOut: () => void;
};

export const ChartLegend = memo(
  ({ chartId, legendItems, toggledSeries, chartHeight, onLegendClick, onMouseOver, onMouseOut }: ChartLegendProps) => {
    const getSeriesOpacity = useCallback(
      (seriesId: string) => {
        if (isEmpty(toggledSeries)) {
          return 1;
        }
        return toggledSeries.includes(seriesId) ? 1 : 0.5;
      },
      [toggledSeries]
    );

    return (
      <Scrollbar
        sx={{
          height: `${chartHeight}px`,
          overflowY: 'auto',
        }}
        id={`apex-legend-${chartId}`}
        className="apexcharts-legend"
        onMouseOut={onMouseOut}
      >
        {legendItems.map((item, index) => (
          <Box
            className="apexcharts-legend-series"
            onClick={(event) => onLegendClick(event, item.id)}
            onMouseOver={(event) => onMouseOver(event, item.id)}
            key={`${item.id}-${item.name}-${index}`}
            sx={{
              opacity: getSeriesOpacity(item.id),
            }}
          >
            <Typography variant="caption" sx={{ color: item.color, fontSize: '0.85rem' }} className="apexcharts-legend-marker">
              <Circle fontSize="inherit" />
            </Typography>
            <Typography variant="caption" className="apexcharts-legend-text">
              {item.name}
            </Typography>
          </Box>
        ))}
      </Scrollbar>
    );
  }
);

// This sets a display name for the ChartLegend component which is useful for debugging purposes
// Since ChartLegend is wrapped in memo(), React DevTools would show "Memo" by default
// Setting displayName ensures it shows up as "ChartLegend" in React DevTools instead
ChartLegend.displayName = 'ChartLegend';
