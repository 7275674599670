import { replacePathIds } from '@diagrid/cloud-ui-shared';
import { DeveloperBoardSharp, WebAssetSharp } from '@mui/icons-material';
import { alpha, Box, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocales } from 'src/hooks/useLocales';
import { PATH_DASHBOARD } from 'src/routes/paths';

type QuickFindItemProps = {
  item: QuickFindItemType;
  onClick?: () => void;
};
export type QuickFindItemType = {
  id: string;
  name: string;
  clusterId?: string;
  namespace?: string;
  clusterName?: string;
};

export const QuickFindItem = ({ item, onClick = () => {} }: QuickFindItemProps) => {
  const { translate } = useLocales();
  const theme = useTheme();
  const navigate = useNavigate();
  const type = item.clusterId ? 'app' : 'cluster';
  const isDarkMode = theme.palette.mode === 'dark';
  const link = useMemo(() => {
    switch (type) {
      case 'cluster':
        return replacePathIds(PATH_DASHBOARD.clusters.detail, [
          [':id', item.id],
          [':tab', 'summary'],
        ]);
      case 'app':
        return replacePathIds(PATH_DASHBOARD.clusterApps.detail, [
          [':appId', item.id],
          [':clusterId', item.clusterId],
          [':tab', 'summary'],
        ]);
      default:
        return '#';
    }
  }, [type, item]);

  const handleClick = () => {
    navigate(link);
    onClick();
  };

  return (
    <Box
      component="button"
      sx={{
        backgroundColor: isDarkMode ? theme.palette.grey[800] : theme.palette.grey[300],
        borderRadius: 1,
        my: 1,
        px: 2,
        py: 1.2,
        display: 'flex',
        width: '100%',
        gridGap: '0.5rem',
        position: 'relative',
        transition: 'all 0.2s ease-in-out',
        color: theme.palette.text.primary,
        textAlign: 'left',
        border: 'none',
        fontSize: '1rem',
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.main, 0.25),
          cursor: 'pointer',
        },
      }}
      tabIndex={0}
      onClick={handleClick}
    >
      {type === 'cluster' ? <DeveloperBoardSharp /> : <WebAssetSharp />}
      <Box sx={{ mt: 0.1 }}>
        <Box
          sx={{
            position: 'absolute',
            top: '0.875rem',
            right: '1rem',
            fontSize: '0.675rem',
            textTransform: 'uppercase',
            opacity: 0.5,
          }}
        >
          {type}
        </Box>

        <Box sx={{ fontWeight: 700, lineHeight: 1.4, width: '85%' }}>
          {item.name}
          {item.namespace && (
            <Box
              component="span"
              sx={{
                fontSize: '0.875rem',
                opacity: 0.5,
                ml: 1,
                padding: '0.2rem 0.35rem',
                borderRadius: 0.5,
                border: `1px solid ${theme.palette.grey[600]}`,
              }}
            >
              {item.namespace}
            </Box>
          )}
        </Box>
        {item.clusterName && (
          <Box sx={{ fontSize: '0.875rem', opacity: 0.75, mt: '0.25rem' }}>
            <Box component="span" sx={{ opacity: 0.75, fontSize: '0.675rem', textTransform: 'uppercase' }}>
              {translate('generalLabels.cluster')}:
            </Box>{' '}
            {item.clusterName}
          </Box>
        )}
      </Box>
    </Box>
  );
};
