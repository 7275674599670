import { Box, Popover, PopoverProps } from '@mui/material';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { PropsWithChildren } from 'react';

type MenuPopoverProps = {
  disabledArrow?: boolean;
  borderColor?: string;
  arrowColor?: string;
  arrow?:
    | 'top-left'
    | 'top-center'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-center'
    | 'bottom-right'
    | 'left-top'
    | 'left-center'
    | 'left-bottom'
    | 'right-top'
    | 'right-center'
    | 'right-bottom';
  sx?: SxProps<Theme>;
} & PopoverProps;

export function MenuPopover({
  children,
  arrow = 'top-right',
  disabledArrow,
  borderColor,
  arrowColor,
  sx,
  ...other
}: PropsWithChildren<MenuPopoverProps>) {
  const theme = useTheme();

  const SIZE = 12;

  const POSITION = -((SIZE + 2) / 2);

  const borderColorStyle = borderColor
    ? borderColor
    : theme.palette.mode === 'dark'
      ? theme.palette.grey['700']
      : theme.palette.grey['300'];

  const borderStyle = `solid 1px ${borderColorStyle}`;

  const topStyle = {
    borderRadius: '0 0 3px 0',
    top: POSITION,
    borderBottom: borderStyle,
    borderRight: borderStyle,
  };
  const bottomStyle = {
    borderRadius: '3px 0 0 0',
    bottom: POSITION,
    borderTop: borderStyle,
    borderLeft: borderStyle,
  };
  const leftStyle = {
    borderRadius: '0 3px 0 0',
    left: POSITION,
    borderTop: borderStyle,
    borderRight: borderStyle,
  };
  const rightStyle = {
    borderRadius: '0 0 0 3px',
    right: POSITION,
    borderBottom: borderStyle,
    borderLeft: borderStyle,
  };

  const arrowStyle = {
    [theme.breakpoints.up('sm')]: {
      zIndex: 1,
      width: SIZE,
      height: SIZE,
      content: "''",
      position: 'absolute',

      transform: 'rotate(-135deg)',
      background: arrowColor ? arrowColor : theme.palette.background.paper,
    },
    // Top
    ...(arrow === 'top-left' && { ...topStyle, left: 20 }),
    ...(arrow === 'top-center' && {
      ...topStyle,
      left: 0,
      right: 0,
      margin: 'auto',
    }),
    ...(arrow === 'top-right' && { ...topStyle, right: 20 }),
    // Bottom
    ...(arrow === 'bottom-left' && { ...bottomStyle, left: 20 }),
    ...(arrow === 'bottom-center' && {
      ...bottomStyle,
      left: 0,
      right: 0,
      margin: 'auto',
    }),
    ...(arrow === 'bottom-right' && { ...bottomStyle, right: 20 }),
    // Left
    ...(arrow === 'left-top' && { ...leftStyle, top: 20 }),
    ...(arrow === 'left-center' && {
      ...leftStyle,
      top: 0,
      bottom: 0,
      margin: 'auto',
    }),
    ...(arrow === 'left-bottom' && { ...leftStyle, bottom: 20 }),
    // Right
    ...(arrow === 'right-top' && { ...rightStyle, top: 20 }),
    ...(arrow === 'right-center' && {
      ...rightStyle,
      top: 0,
      bottom: 0,
      margin: 'auto',
    }),
    ...(arrow === 'right-bottom' && { ...rightStyle, bottom: 20 }),
  };

  return (
    <Popover
      data-cy="action-menu"
      className="action-menu-popover"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      slotProps={{
        paper: {
          sx: {
            p: 1,
            minWidth: 200,
            overflow: 'inherit',
            ...(borderColorStyle && { borderColor: borderColorStyle }),
            ...sx,
          },
        },
      }}
      {...other}
    >
      {!disabledArrow && <Box component="span" sx={arrowStyle} />}

      {children}
    </Popover>
  );
}
