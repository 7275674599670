let getAccessTokenSilently = (_args?) => undefined;
let getUserFromAuth0 = null;

export const security = {
  logout: (_args?) => {
    console.warn('Using default logout implementation. This should be overridden.');
    window.location.href = window.location.origin;
  },
  loginWithRedirect: (_args?) => {
    console.warn('Using default loginWithRedirect implementation. This should be overridden.');
    window.location.href = window.location.origin;
  },
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (func?) => (getAccessTokenSilently = func),
  getAuth0User: () => getUserFromAuth0,
  setAuth0User: (user) => (getUserFromAuth0 = user),
};
